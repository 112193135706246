<template>
  <v-list-item
    :dense="count > 0"
    class="my-n3 px-0"
  >
    <v-list-item-action
      v-if="count"
      class="mr-2 align-center"
    >
      <v-hover v-slot="{ hover }">
        <v-avatar
          color="grey"
          :size="26"
          class="text-caption"
          outlined
          @click="$emit('deleteRegimen')"
        >
          <v-icon
            v-if="hover"
            small
          >
            mdi-close
          </v-icon>
          <span
            v-else
            v-text="count"
          />
        </v-avatar>
      </v-hover>
      <v-icon
        v-if="draggable"
        class="handle mt-2"
        small
      >
        fa fa-bars
      </v-icon>
      <v-spacer />
    </v-list-item-action>
    <v-list-item-content>
      <v-row
        v-for="(prescription, index) in regimen.regimens"
        :key="prescription.drug_id"
        class="ma-0 pa-0"
        :class="{ 'mb-n1': count }"
        dense
      >
        <v-col
          md="4"
          cols="3"
        >
          <drug-picker
            v-model="prescription.drug"
            :readonly="readonly"
            :disabled="!indication && !regimen.indication_id"
            :rules="rules.required"
            :source="source"
            :indication="indication"
            :allowed-drugs="organisms.flatMap(x => x.effective_drugs).filter(x => !!x).map(x => x.id)"
            :resistance="resistanceGenes.flatMap(x => x.resistance).filter((v, i, a) => a.findIndex(x => x.id === v.id) === i).filter(x => relevantResistance.includes(x.name.en))"
            :sensitivities="organisms.flatMap(x => x.result.sensitivities).filter(x => x).map(x => ({ name: x.name, result: x.values.result }))"
            :allergies="allergies"
            :age-bracket="ageBracket"
            :pediatric="pediatric"
            :pregnant="pregnant"
            :breastfeeding="breastfeeding"
            :formulary="formulary"
            :method="method"
            :hide-arkscore="count > 0"
            show-short-name
            :dense="count > 0"
            :label="$tc('drug', 1) | capitalize"
            @input="setRegimen(prescription)"
          />
        </v-col>
        <v-col>
          <v-select
            v-model="prescription.pivot.drug_regimen_id"
            :disabled="(!indication && !regimen.indication_id) || !prescription?.drug?.id"
            :items="filteredRegimens(prescription)"
            :readonly="readonly || prescription.pivot.drug_regimen_id && filteredRegimens(prescription).length == 1 && filteredRegimens(prescription).map(x => x.id).includes(prescription.pivot.drug_regimen_id)"
            :rules="rules.required"
            :menu-props="{ offsetY: true }"
            :dense="count > 0"
            :label="`${$t('dosage')}${$t('ampersand')}${$t('duration')}`"
            item-value="id"
            :no-data-text="`No ${ageBracket} regimens available for ${prescription?.drug?.short_name}`"
          >
            <template #selection="{ item }">
              <span :class="{ 'condensed': (item.dosage + item.duration).length > 40 }">
                {{ item.dosage }} x {{ item.duration }}
              </span>
            </template>
            <template #item="{ item }">
              <v-list-item-content>
                {{ item.dosage }} x {{ item.duration }}
                <v-list-item-subtitle v-if="item.indications.length">
                  {{ item.indications.map(x => x.internal_name ? x.internal_name : (x.indication[$i18n.locale] || x.indication.en)).join(', ') }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </template>
            <template
              v-if="filteredRegimens(prescription).length == 1 && filteredRegimens(prescription).map(x => x.id).includes(prescription.pivot.drug_regimen_id)"
              #append
            >
              &nbsp;
            </template>
          </v-select>
        </v-col>
        <v-col class="shrink">
          <v-select
            v-model="prescription.pivot.connector"
            :readonly="readonly"
            :disabled="!indication && !regimen.indication_id"
            :items="regimen.regimens.length > 1 ? ['with', 'or', 'then', ''] : ['with', 'or', 'then']"
            :menu-props="{ offsetY: true }"
            :dense="count > 0"
            @change="addRemoveDrug(index, prescription.pivot.connector)"
          >
            <template #item="{ item }">
              <v-icon
                v-if="!item"
                key="fal fa-trash-alt"
                small
              >
                fal fa-trash-alt
              </v-icon>
              <v-list-item-title v-else>
                {{ item }}
              </v-list-item-title>
            </template>
          </v-select>
        </v-col>
      </v-row>
      <v-row
        class="ma-0 pa-0"
        dense
      >
        <v-col>
          <v-select
            v-model="regimen.indication_id"
            :items="selectable_indications"
            :readonly="readonly || selectable_indications.length == 1 && indications.some(x => x.value == indication)"
            :error-messages="indications.length && !regimen.indication_id ? ['Please select an indication first'] : null"
            :rules="rules.required"
            :menu-props="{ offsetY: true }"
            no-data-text="No indications available for the detected organisms"
            :label="$tc('indication', 1) | capitalize"
            dense
            @change="setIndication"
          >
            <template
              v-if="selectable_indications.length == 1 && indications.some(x => x.value == indication)"
              #append
            >
              &nbsp;
            </template>
            <template #selection="{ item }">
              {{ $t('for possible', { indication: item.text }) }}
            </template>
            <template #item="{ item }">
              <v-list-item-content>
                {{ item.internal_name ? item.internal_name : item.text }}
              </v-list-item-content>
              <v-list-item-icon
                v-if="item.default"
              >
                <v-icon small>
                  fal fa-star
                </v-icon>
              </v-list-item-icon>
            </template>
          </v-select>
        </v-col>
      </v-row>
    </v-list-item-content>
  </v-list-item>
</template>
<script>
export default {
  components: {
    DrugPicker: () => import('@/components/drug/DrugPicker.vue'),
  },
  props: {
    regimen: {
      type: Object,
      default: () => ({
        indication_id: null,
        regimens: [{
          dosage: null,
          duration: null,
          drug: {},
          pivot: {
            connector: null,
          },
        }],
      }),
    },
    count: {
      type: Number,
      default: null,
    },
    uuid: {
      type: String,
      required: true,
    },
    organisms: {
      type: Array,
      default: () => ([]),
    },
    resistanceGenes: {
      type: Array,
      default: () => ([]),
    },
    relevantResistance: {
      type: Array,
      default: () => ([]),
    },
    diagnoses: {
      type: Array,
      default: () => ([]),
    },
    allergies: {
      type: Array,
      default: () => ([]),
    },
    source: {
      type: String,
      default: null,
    },
    gender: {
      type: String,
      default: null,
    },
    formulary: {
      type: Object,
      default: () => ({}),
    },
    method: {
      type: String,
      default: null,
    },
    ageBracket: {
      type: String,
      default: 'adult',
    },
    pediatric: Boolean,
    pregnant: Boolean,
    breastfeeding: Boolean,
    readonly: Boolean,
    draggable: Boolean,
  },
  data () {
    return {
      rules: {
        required: [
          v => !!v || this.$t('required'),
        ],
      },
      indication: this.regimen ? this.regimen.indication_id : null,
    }
  },
  computed: {
    indications () {
      if (this.source && this.$store.getters.getIndications.length) {
        let indications = this.$store.getters.getIndications
          .filter(x => x.sources.includes(this.source))
          .filter(x => !x.hide_from_qa)

        if (!['male', 'female'].includes(this.gender)) {
          indications = indications.filter(x => !x.gender || !['male', 'female'].includes(x.gender))
        } else {
          indications = indications.filter(x => !x.gender || x.gender == this.gender)
        }

        // Filter pregnancy related data
        if (this.pregnant) {
          indications = indications.filter(x => !x.hide_when_pregnant)
        } else {
          indications = indications.filter(x => !x.only_when_pregnant)
        }

        // Filter diagnosis related data
        if (this.diagnoses.map(x => x.group_id).filter((value, index, array) => array.indexOf(value) === index).length == 1) {
          let diagnosis_groups = this.diagnoses.flatMap(x => x.group ? x.group.id : null)
          indications = indications.filter(x => !x.diagnosis_groups.length || x.diagnosis_groups.map(x => x.id).some(y => diagnosis_groups.includes(y)))
        } else {
          indications = indications.filter(x => !x.diagnosis_groups.length)
        }

        // Filter organism related data
        let organism_categories = this.organisms.flatMap(x => x.category ? x.category.id : null)
        indications = indications.filter(x => !x.organism_categories.length || x.organism_categories.map(x => x.id).some(y => organism_categories.includes(y)))

        return indications.map(x => ({
          text: x.indication[this.$i18n.locale] || x.indication.en,
          value: x.id,
          internal_name: x.internal_name,
          special_dosing: x.special_dosing,
          default: x.gender && x.gender != this.gender ? false : x.default,
          order: x.order,
        })).sort((a,b) => a.order > b.order ? 1 : -1)
      } else {
        return []
      }
    },
    selectable_indications () {
      return this.indications.length ? this.indications : []
    },
    possible_indications () {
      if (this.indication && this.indications.length) {
        let indication = this.indications.find(x => x.value == this.indication)?.text
        return this.indications.filter(x => x?.text == indication).map(x => x.value)
      }
      return this.indications.length ? this.indications.map(x => x.value) : []
    },
  },
  watch: {
    uuid () {
      this.indication = this.regimen.indication_id
    },
    regimen: {
      deep: true,
      handler () {
        if (!this.regimen) {
          this.initializeRegimen
        }
        if (this.regimen && this.indications.length && !this.regimen.indication_id) {
          this.indication = this.regimen.indication_id =
            this.selectable_indications.length > 1 ?
            this.indications.find(x => x.default)?.value :
            this.selectable_indications[0].value
        } else if (this.regimen && this.regimen.indication_id) {
          this.indication = this.regimen.indication_id
        }
        this.$emit('change', this.regimen)
      },
    },
    indications () {
      if (this.regimen && this.indications.length && !this.regimen.indication_id) {
        this.indication = this.regimen.indication_id =
          this.selectable_indications.length > 1 ?
          this.indications.find(x => x.default)?.value :
          this.selectable_indications[0].value
      } else if (this.regimen && this.regimen.indication_id) {
        this.indication = this.regimen.indication_id
      }
    },
  },
  created() {
    if (!this.$store.getters.getIndications.length) {
      this.$store.dispatch("fetchIndications", { self: this, params: { qa: true }})
    }
    if (this.regimen) {
      this.indication = this.regimen.indication_id
    } else {
      this.initializeRegimen
    }
    if (!this.regimen.indication_id && this.selectable_indications.length) {
      this.indication = this.regimen.indication_id =
        this.selectable_indications.length > 1 ?
        this.indications.find(x => x.default)?.value :
        this.selectable_indications[0].value
    }
  },
  methods: {
    initializeRegimen () {
      this.regimen = {
        indication_id: null,
        regimens: [{
          dosage: null,
          duration: null,
          drug: {},
          pivot: {
            connector: null,
          },
        }],
      }
    },
    addRemoveDrug (index, connector) {
      if (connector && index == (this.regimen.regimens.length - 1)) {
        this.regimen.regimens.push({
          pivot: {},
          drug: {
            name: null,
          },
        })
      } else if (!connector) {
        this.regimen.regimens.splice(index, 1)
        this.$set(this.regimen.regimens[index - 1].pivot, 'connector', null)
      }
    },
    setIndication (indication_id) {
      this.indication = indication_id
      Object.values(this.regimen.regimens).forEach(prescription => this.setRegimen(prescription))
    },
    setRegimen (prescription) {
      if (prescription.drug) {
        prescription.pivot.drug_regimen_id = this.filteredRegimens(prescription).length == 1 ? this.filteredRegimens(prescription)[0].id : null
      }
    },
    filteredRegimens (prescription) {
      var regimens = []
      if (prescription && prescription.drug && prescription.drug.regimens && prescription.drug.regimens.length) {
        regimens = prescription.drug.regimens
        regimens = regimens.filter(x => x.indications.length && x.indications.map(x => x.id).some(i => this.possible_indications.includes(i)))

        if (!['male', 'female'].includes(this.gender)) {
          regimens = regimens.filter(x => !x.gender || !['male', 'female'].includes(x.gender))
        } else {
          regimens = regimens.filter(x => !x.gender || x.gender == this.gender)
        }

        let bracket_regimens = regimens.filter(x => x.age_bracket.includes(this.ageBracket))
        regimens = bracket_regimens.length ? bracket_regimens : regimens.filter(x => x.pediatric == this.pediatric)
      }
      if (regimens.flatMap(x => x.indications).find(x => x.special_dosing)) {
        regimens = regimens.filter(x => x.indications.filter(x => x.special_dosing).length > 0)
      }
      if (!regimens.length && (prescription.dosage || prescription.duration)) {
        regimens.push({ id: prescription.id, dosage: prescription.dosage, duration: prescription.duration })
      }
      // if (!regimens.length) {
      //   regimens.push({ id: 0, dosage: this.pediatric ? 'pediatric dosed' : 'dosage not defined', duration: 'undefined duration' })
      // }
      return regimens
    },
  },
}
</script>
<style>
.condensed {
  font-family: 'Roboto Condensed';
}
.handle {
  cursor: ns-resize;
}
</style>
